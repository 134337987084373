footer {
    background-color: white;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer_desktop {
    padding: 16px 64px;
}

.footer_mobile {
    padding: 16px 16px;
}

.footer_desktop {
    padding: 16px 64px;
}

.footer_mobile {
    padding: 16px 16px;
}

.footer__sections {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    justify-content: space-around;

    margin-bottom: 16px;
}

.footer__logos {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.footer__logo {
    padding: 16px;
    width: 64px;
    height: auto;
}

.footer__social-media {
    padding: 32px;
    font-size: 32px;
}

.footer-social-media__link {
    color: black;
}

.footer__powered-by {
    padding: 16px;
}