.combobox {
    position: relative;
}

.combobox__select {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 8px;

    border: 2px solid #333333;
    border-radius: 4px;

    cursor: pointer;
    background-color: #eeeeee;
}

.combobox__options {
    margin: 0;
    padding: 4px 0;
    list-style-type: none;
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 32px;

    background-color: #EEEEEE;
    border-left: 2px solid #333333;
    border-right: 2px solid #333333;
    border-bottom: 2px solid #333333;

    z-index: 110;
}

.combobox__option {
    padding: 4px 8px;
    height: 24px;
}

.combobox__option:hover {
    background-color: #FFFFFF;
}