body {
  background-color: #EEEEEE;
  display: flex;
  flex-direction: column;

  margin: 0;
}

p {
  text-align: center;
}

h4, h1 {
  text-align: center;
}


a {
  color: #54a7b9;
  text-decoration: none;
}

.app {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.primary-button {
  border-radius: 25px;
  border: none;
  cursor: pointer;

  text-decoration: none;
  text-align: center;
  color: white;
  padding: 16px 32px;
  white-space: nowrap;

  width: 120px;
  background-color: black;
}