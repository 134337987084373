.gallery-preview {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.gallery-preview__info {
    position: relative;

    border-radius: 80px;
    display: flex;
    justify-content: space-around;
    align-items: center;

    padding: 16px 8px;

    color: #FFFFFF;

    background: #000000;
    width: 340px;
    height: 80px;
}

.gallery-preview__link {
    color: #FFFFFF;
    text-decoration: none;
    text-align: center;

    width: 180px;;
    max-width: 180px;
}

.gallery-preview__indicators {
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    list-style: none;
}

.gallery-preview__indicator {
    display: flex;
    width: 12px;
    height: 12px;

    margin: 0 10px;
    background-color: black;
    cursor: pointer;
    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
}

.gallery-preview__indicator_selected {
    width: 20px;
    height: 20px;
}