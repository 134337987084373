.button {
    font-family: 'HiPiilaniItalic',  sans-serif;
    font-size: 16px;
    font-weight: bold;
    padding: 8px 32px;
    border-radius: 4px;
    border: 2px solid black;

    cursor: pointer;
}

.button_primary {
    background-color: black;
    color: #FFFFFF;
}

.button_secondary {
    background-color: #FFFFFF;
    color: black;
}