.landing-section{
    display: flex;

    scroll-margin: 82px;
    flex-direction: column;
    align-items: center;
}

.landing-section_desktop {
    padding: 16px 64px;
}

.landing-section_mobile {
    padding: 16px 32px;
}

.landing-section__header {
    text-align: center;
    padding-bottom: 16px;
    font-size: 32px;
}

.landing-section__content {
    display: flex;
    flex-direction: column;
    align-items: center;
}