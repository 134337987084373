.solutions {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.solutions__item {
    width: 40%;
    min-width: 360px;
    background-color: #FFFFFF;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    padding-bottom: 16px;
    margin-bottom: 64px;
}

.solutions-item__image {
    width: 100%;
    height: 500px;
    object-fit: cover;
    clip-path: polygon(100% 0, 100% 90%, 0 100%, 0 0);
}

.solutions-item__description {
    text-align: center;
    padding: 32px 64px 64px 64px;
}