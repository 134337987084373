.contacts-section {
    display: flex;

    scroll-margin: 82px;
    flex-direction: column;
    align-items: center;

    padding-bottom: 64px;
}

.contacts__content {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.contacts__gmap {
    font-size: 24px;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background: black;
}


.contacts-section_desktop {
    padding: 0 64px 64px 64px;
}