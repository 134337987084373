.swipebox {
    width: 100%;
    overflow: hidden;
    position: relative;
    direction: ltr;
}

.swipebox__container {
    position: relative;
    width: 100%;
    height: 100%;
    white-space: nowrap;
}

.swipebox__item {
    width: 100%;
    height: 100%;
    display: inline-block;
    position: relative;

    background: no-repeat;
    background-size: 100% auto;
    background-position: center;
}
