.gallery {
    scroll-margin: 82px;
}

.gallery__container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.gallery__item {
    width: 400px;
    margin-bottom: 16px;
}

.gallery-item__ul {
    padding: 0;
}

.gallery-item__li {
    text-align: center;
    list-style: none;
}

.gallery-item__cost {
    position: relative;
    top: -31px;
    background-color: #54a7b9;
    color: white;
    z-index: 50;
    padding: 8px 16px;
}

.gallery-item-cost__amount {
    padding-left: 16px;
}