
.contacts__ul {
    background: white;
    padding: 16px 16px;
    margin: 0;

    display: flex;
    flex-direction: column;
    align-items: stretch;

    justify-content: space-between;
    list-style: none;
}

.contacts__li {
    display: flex;
    font-size: 24px;

    padding: 8px;
}

.contacts__item {
    margin-left: 16px;
}