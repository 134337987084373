.header_sticky {
    position: fixed;
    top: 0;
    width: 100%;
    height: 82px;

    z-index: 100;
}

.header__content {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    align-items: center;
    height: 100%;

    background-color: #FFFFFF;
}

.header__content_desktop {
    padding: 0 64px;
}

.header__content_mobile {
    padding: 0 32px;
}

.header__logo {
    color: #000000;
    text-decoration: none;

    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100px;
}

.header__logo:hover {
    color: #000000;
}

.header__nav-ul {
    width: 800px;
    display: flex;
    justify-content: space-between;
    list-style: none;
}

.header__nav-li {
    cursor: pointer;
}

.header__nav-a {
    text-decoration: none;
    color: #000000;
    user-select: none;
}

.header__nav-body_mobile {
    position: fixed;
    top: 82px;
    left: 0;

    padding: 0;
    background-color: #000000;

    width: 100%;
    height: 100%;

    z-index: 100;
}

.header__nav-ul_mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    list-style: none;
}

.header__nav-li_mobile {
    cursor: pointer;
    margin: 32px 0;
}

.header__nav-a_mobile {
    text-decoration: none;
    font-size: 32px;
    color: #FFFFFF;
    user-select: none;
}

.header__search-button {
    width: 100px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.header__search-button-title {
    padding-left: 8px;
}

.header__search-body {
    position: fixed;
    top: 82px;
    right: 0;

    padding: 0;
    background-color: #FFFFFF;
    height: 100%;
    width: 0;
    transition: width 0.3s;

    z-index: 100;
}

.header__search-body_desktop {
    box-shadow: -10px 5px 10px rgba(0, 0, 0, 0.2);
    width: 400px;
    transition: width 0.3s;
}

.header__search-body_mobile {
    width: 100%;
}